import wretch, { type WretchResponse } from 'wretch';
import FormDataAddon from 'wretch/addons/formData';
import QueryStringAddon from 'wretch/addons/queryString';

export const AJAX_API = wretch(
  `${window.location.origin}/wp/wp-admin/admin-ajax.php`,
).addon(FormDataAddon);

export const POSTCODE_API = wretch('https://postcode.tech/api/v1/postcode')
  .addon(QueryStringAddon)
  .headers({
    Authorization: `Bearer ${import.meta.env.VITE_POSTCODE_API_KEY}`,
});

export const createCachedAPI = (
  baseUrl: string,
  {
    cacheTime = 15 * 60 * 1000,
  }: {
    cacheTime?: number;
  } = {},
) => {
  const cache = new Map<
    string,
    {
      cachedAt: number;
      response: WretchResponse;
    }
  >();

  return wretch(baseUrl).middlewares([
    (next) => (url, options) => {
      const key = `${options.method}@${url}`;
      const cachedItem = cache.get(key);
      if (cachedItem && Date.now() - cachedItem.cachedAt <= cacheTime) {
        return Promise.resolve(cachedItem.response.clone());
      }

      return next(url, options).then(async (response) => {
        cache.set(key, { cachedAt: Date.now(), response: response.clone() });

        return response;
      });
    },
  ]);
};
