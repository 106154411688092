import { defineModule } from '../utils/helpers';

let selectedFaqCategory: number | null = null;

const getElements = () => ({
  faqCategoryFilterElements: document.querySelectorAll<HTMLElement>(
    '.archive.post-type-archive-faqs [data-faq-category-filter]',
  ),
  faqElements: document.querySelectorAll<HTMLElement>(
    '.archive.post-type-archive-faqs [data-faq-category]',
  ),
});

const onCategoryFilterClick = (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const { faqCategoryFilterElements, faqElements } = getElements();

  const categoryId = e.currentTarget.getAttribute('data-faq-category-filter');
  if (!categoryId) return;
  const parsedCategoryId = parseInt(categoryId);

  selectedFaqCategory =
    selectedFaqCategory === parsedCategoryId ? null : parsedCategoryId;

  faqCategoryFilterElements.forEach((faqCategoryFilterElement) => {
    const isActive =
      selectedFaqCategory && faqCategoryFilterElement === e.currentTarget;
    faqCategoryFilterElement.classList.toggle('active', Boolean(isActive));
    if (!isActive) {
      faqCategoryFilterElement.classList.remove('active');
    }
  });

  faqElements.forEach((faqElement) =>
    faqElement.classList.toggle(
      'hidden',
      selectedFaqCategory !== null &&
        parseInt(faqElement.getAttribute('data-faq-category') || '') !==
          selectedFaqCategory,
    ),
  );
};

export default defineModule(
  () => {
    const { faqCategoryFilterElements } = getElements();

    faqCategoryFilterElements.forEach((faqCategoryFilterElement) => {
      faqCategoryFilterElement.addEventListener('click', onCategoryFilterClick);
    });
  },
  () => {
    selectedFaqCategory = null;

    const { faqCategoryFilterElements } = getElements();

    faqCategoryFilterElements.forEach((faqCategoryFilterElement) => {
      faqCategoryFilterElement.removeEventListener(
        'click',
        onCategoryFilterClick,
      );
    });
  },
);
