import { debounce } from 'lodash-es';
import { POSTCODE_API } from '../utils/api';
import { defineModule } from '../utils/helpers';

const getElements = () => ({
  formElements: document.querySelectorAll<HTMLElement>('.gform_wrapper'),
});

const zipcodeLookup = async (el: HTMLElement) => {
  const formElement = el.closest<HTMLFormElement>('form');
  if (!formElement) return;

  const zipcodeInputElement = formElement.querySelector<HTMLInputElement>(
    '.lookup__zipcode input',
  );
  const houseNumberInputElement = formElement.querySelector<HTMLInputElement>(
    '.lookup__house-number input',
  );
  const streetInputElement = formElement.querySelector<HTMLInputElement>(
    '.lookup__street input',
  );
  const cityInputElement = formElement.querySelector<HTMLInputElement>(
    '.lookup__city input',
  );

  if (
    !zipcodeInputElement ||
    !houseNumberInputElement ||
    !streetInputElement ||
    !cityInputElement ||
    !zipcodeInputElement.value.length ||
    !houseNumberInputElement.value.length
  ) {
    return;
  }

  try {
    const { city, street } = await POSTCODE_API.query({
      postcode: zipcodeInputElement.value,
      number: houseNumberInputElement.value.replace(/\D+/g, ''),
    })
      .get()
      .json<{ city: string; street: string }>();

    streetInputElement.value = street;
    cityInputElement.value = city;
    streetInputElement.readOnly = true;
    cityInputElement.readOnly = true;
  } catch (error) {
    streetInputElement.value = '';
    cityInputElement.value = '';
    streetInputElement.readOnly = false;
    cityInputElement.readOnly = false;
  }
};

const debouncedZipcodeLookup = debounce(
  (el: HTMLElement) => zipcodeLookup(el),
  300,
);

const onGFormInputChange = (
  el: HTMLElement,
  _formId: number,
  _fieldId: number,
) => {
  if (
    !['lookup__zipcode', 'lookup__house-number'].some((cls) =>
      el.closest<HTMLElement>('.gfield')?.classList.contains(cls),
    )
  ) {
    return;
  }

  debouncedZipcodeLookup(el);
};

export default defineModule(() => {
  const { formElements } = getElements();
  const { gform } = window;

  if (!formElements || !gform) return;

  gform.addAction('gform_input_change', onGFormInputChange, 10);
});
