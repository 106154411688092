import type { autoUpdate } from '@floating-ui/dom';
import { v4 as uuidv4 } from 'uuid';
import { createElement, defineModule } from '../utils/helpers';

const autoUpdates: ReturnType<typeof autoUpdate>[] = [];

const getElements = () => ({
  tooltipTriggerElements:
    document.querySelectorAll<HTMLElement>('[data-tooltip]'),
  tooltipElements: document.querySelectorAll<HTMLElement>('.tooltip'),
});

const updateTooltip = async (
  buttonElement: HTMLElement,
  tooltipElement: HTMLElement,
) => {
  const { computePosition, offset, autoPlacement, arrow } = await import(
    '@floating-ui/dom'
  );

  const arrowElement =
    tooltipElement.querySelector<HTMLElement>('.tooltip__arrow');

  const { middlewareData, placement, x, y } = await computePosition(
    buttonElement,
    tooltipElement,
    {
      middleware: [
        offset(8),
        autoPlacement(),
        arrowElement && arrow({ element: arrowElement }),
      ],
    },
  );

  Object.assign(tooltipElement.style, {
    left: `${x}px`,
    top: `${y}px`,
  });

  if (middlewareData.arrow && arrowElement) {
    const { x: arrowX, y: arrowY } = middlewareData.arrow;

    const staticSide = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[placement.split('-')[0]];

    Object.assign(arrowElement.style, {
      left: arrowX != null ? `${arrowX}px` : '',
      top: arrowY != null ? `${arrowY}px` : '',
      right: '',
      bottom: '',
      ...(staticSide && {
        [staticSide]: `${-arrowElement.offsetHeight / 2}px`,
      }),
    });
  }
};

const showTooltip = async (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const tooltipId = e.currentTarget.getAttribute('aria-describedby');
  if (!tooltipId) return;

  const tooltipTriggerElement = document.querySelector<HTMLElement>(
    `[data-tooltip][aria-describedby='${tooltipId}']`,
  );
  if (!tooltipTriggerElement) return;

  const tooltipElement = document.getElementById(tooltipId);
  if (!tooltipElement) return;

  const { autoUpdate } = await import('@floating-ui/dom');

  tooltipElement.classList.remove('tooltip--hidden');
  tooltipElement.ariaHidden = 'false';

  autoUpdates.push(
    autoUpdate(tooltipTriggerElement, tooltipElement, async () => {
      await updateTooltip(tooltipTriggerElement as HTMLElement, tooltipElement);
    }),
  );
};

const hideTooltip = (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const tooltipId = e.currentTarget.getAttribute('aria-describedby');
  if (!tooltipId) return;

  const tooltipElement = document.getElementById(tooltipId);
  if (!tooltipElement) return;

  tooltipElement.classList.add('tooltip--hidden');
  tooltipElement.ariaHidden = 'true';
};

const events = [
  ['mouseenter', showTooltip],
  ['mouseleave', hideTooltip],
  ['focus', showTooltip],
  ['blur', hideTooltip],
] as const;

export default defineModule(
  () => {
    const { tooltipTriggerElements } = getElements();
    if (!tooltipTriggerElements.length) return;

    tooltipTriggerElements.forEach((tooltipTriggerElement) => {
      const tooltipContent = tooltipTriggerElement.getAttribute('data-tooltip');
      if (!tooltipContent?.length) return;

      const tooltipElement = createElement(
        'div',
        {
          id: uuidv4(),
          role: 'tooltip',
          className: 'tooltip tooltip--hidden',
          ariaHidden: 'true',
        },
        [
          tooltipContent,
          createElement('span', {
            className: 'tooltip__arrow',
          }),
        ],
      );
      document.body.appendChild(tooltipElement);

      tooltipTriggerElement.setAttribute('aria-describedby', tooltipElement.id);

      events.forEach(([event, listener]) => {
        tooltipTriggerElement.addEventListener(event, listener);
      });
    });
  },
  () => {
    const { tooltipElements, tooltipTriggerElements } = getElements();

    tooltipTriggerElements.forEach((tooltipTriggerElement) => {
      events.forEach(([event, listener]) => {
        tooltipTriggerElement.removeEventListener(event, listener);
      });
    });

    while (autoUpdates.length > 0) {
      autoUpdates.pop()?.();
    }

    tooltipElements.forEach((tooltipElement) => tooltipElement.remove());
  },
);
