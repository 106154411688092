import { defineModule } from '../utils/helpers';

const getElements = () => ({
  formElements: document.querySelectorAll<HTMLElement>('.gform_wrapper'),
  formInputs: document.querySelectorAll<HTMLElement>(
    '.gform_wrapper [id^="input_"]',
  ),
});

export default defineModule(
  () => {
    // nothing to setup
  },
  () => {
    const { formElements, formInputs } = getElements();
    if (!formElements) return;

    const { gform, jQuery } = window;
    if (!gform) return;

    gform.scriptsLoaded = false;

    if (!jQuery) return;

    ['gform_post_render', 'gform_post_conditional_logic'].forEach(
      (gformEvent) => {
        jQuery(document).off(gformEvent);
      },
    );

    if (typeof jQuery.fn.unmask === 'function') {
      formInputs.forEach((input) => jQuery(input).unmask());
    }
  },
);
