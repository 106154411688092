import { BREAKPOINT_MEDIA_QUERIES } from '../utils/breakpoints';
import { defineModule } from '../utils/helpers';

const getElements = () => ({
  accordionElements: document.querySelectorAll<HTMLElement>('.accordion'),
  accordionTableElements: document.querySelectorAll<HTMLElement>(
    '.accordion--table .accordion',
  ),
});

const onAccordionButtonClick = (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const contentBlockElement =
    e.currentTarget.closest<HTMLElement>('.content-block');
  const accordionElement = e.currentTarget.closest<HTMLElement>('.accordion');
  if (!contentBlockElement || !accordionElement) return;

  const isOpen = accordionElement.classList.contains('accordion--open');
  const isTable = contentBlockElement.classList.contains('accordion--table');

  const accordionElements =
    contentBlockElement.querySelectorAll<HTMLElement>('.accordion');
  accordionElements.forEach((_accordionElement) => {
    const isCurrentElement = _accordionElement === accordionElement;
    const shouldOpen =
      (isCurrentElement && !isOpen) ||
      (isTable && BREAKPOINT_MEDIA_QUERIES.lg.matches);

    _accordionElement.classList.toggle('accordion--open', shouldOpen);

    const accordionTriggerElement =
      _accordionElement.querySelector<HTMLElement>('.accordion__trigger');
    const accordionContentElement =
      _accordionElement.querySelector<HTMLElement>('.accordion__content');
    if (!accordionTriggerElement || !accordionContentElement) return;

    accordionTriggerElement.setAttribute(
      'aria-expanded',
      shouldOpen.toString(),
    );
    accordionContentElement.setAttribute(
      'aria-hidden',
      (!shouldOpen).toString(),
    );
  });
};

const accordionBreakpointChecker = () => {
  const { accordionTableElements } = getElements();
  if (!accordionTableElements.length) return;

  const accordionTable = accordionTableElements.item(0);
  accordionTable.setAttribute('aria-expanded', String(true));

  const trigger = accordionTable.querySelector('.accordion__trigger');
  trigger?.dispatchEvent(new Event('click'));
};

export default defineModule(
  () => {
    const { accordionElements } = getElements();

    accordionElements.forEach((accordionElement) => {
      const accordionTriggerElement =
        accordionElement.querySelector<HTMLButtonElement>(
          '.accordion__trigger',
        );
      if (!accordionTriggerElement) return;

      accordionTriggerElement.addEventListener('click', onAccordionButtonClick);
    });

    BREAKPOINT_MEDIA_QUERIES.lg.addEventListener(
      'change',
      accordionBreakpointChecker,
      { passive: true },
    );
  },
  () => {
    const { accordionElements } = getElements();

    accordionElements.forEach((accordionElement) => {
      const accordionTriggerElement =
        accordionElement.querySelector<HTMLButtonElement>(
          '.accordion__trigger',
        );
      if (!accordionTriggerElement) return;

      accordionTriggerElement.removeEventListener(
        'click',
        onAccordionButtonClick,
      );
    });

    BREAKPOINT_MEDIA_QUERIES.lg.removeEventListener(
      'change',
      accordionBreakpointChecker,
    );
  },
);
