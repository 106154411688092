import type { WP_REST_API_Post } from 'wp-types';
import QueryStringAddon from 'wretch/addons/queryString';
import { createCachedAPI } from '../utils/api';

export const TALENT_API = createCachedAPI(
  `${window.location.origin}/wp-json/wp/v2/talent`,
).addon(QueryStringAddon);

export const getTalent = (id: number | string) =>
  TALENT_API.query({ _fields: 'title.rendered,acf', _embed: true })
    .get(`/${id}`)
    .json<{
      title: WP_REST_API_Post['title'];
      acf: {
        'post_type-talent-availability': string;
        'post_type-talent-available': boolean;
        'post_type-talent-description': string;
        'post_type-talent-expertise': string;
        'post_type-talent-skills': number[];
      };
      _embedded: {
        'acf:term': {
          id: number;
          link: string;
          name: string;
          slug: string;
          taxonomy: string;
        }[];
      };
    }>()
    .catch(() => null);
