import type Splide from '@splidejs/splide';
import { defineModule } from '@/js/utils/helpers';

const splides: Splide[] = [];

const getElements = () => ({
  splideElements: document.querySelectorAll<HTMLElement>(
    '.content-block--stories-slider .splide',
  ),
});

export default defineModule(
  async () => {
    const { splideElements } = getElements();
    if (!splideElements) return;

    const { Splide } = await import('@splidejs/splide');

    splideElements.forEach((splideElement) => {
      const splide = new Splide(splideElement, {
        type: 'loop',
        pagination: false,
        gap: '20rem',
        autoplay: true,
      });

      splides.push(splide.mount());
    });
  },
  () => {
    while (splides.length > 0) {
      splides.pop()?.destroy(true);
    }
  },
);
