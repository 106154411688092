import { createFocusTrap, type FocusTrap } from 'focus-trap';
import { defineModule, nextTick } from '../utils/helpers';
import { BREAKPOINT_MEDIA_QUERIES } from '../utils/breakpoints';
import { Overlay, hasOpenOverlay, toggleOverlay } from '../utils/overlays';

let focusTrap: FocusTrap | null = null;

const getElements = () => ({
  navbar: document.querySelector<HTMLElement>('.navbar'),
  menuElement: document.querySelector<HTMLElement>('.navbar__menu'),
  menuTogglerElement: document.querySelector<HTMLElement>('.menu__toggler'),
  subMenuTogglerElements: document.querySelectorAll<HTMLElement>(
    '.navbar .sub-menu__toggle',
  ),
  navSubItems: document.querySelectorAll<HTMLLIElement>(
    '.navbar .menu-item-has-children',
  ),
  emptySubLinks: [
    ...document.querySelectorAll<HTMLAnchorElement>(
      '.navbar .menu-item-has-children > .menu-item__link',
    ),
  ].filter((a) => !a.hasAttribute('href') || a.getAttribute('href') === '#'),
});

export const toggleMenu = (force?: boolean) => {
  const { navbar, menuTogglerElement } = getElements();
  if (!navbar || !menuTogglerElement) return;

  toggleOverlay(Overlay.MENU, navbar.classList.toggle('menu--open', force));
  const expand = hasOpenOverlay(Overlay.MENU);
  menuTogglerElement.ariaExpanded = `${expand}`;

  if (expand) {
    focusTrap = createFocusTrap(navbar, {
      initialFocus: navbar.querySelector<HTMLElement>('.menu-item')!,
      allowOutsideClick: false,
      escapeDeactivates: false,
    });
    return;
  }

  focusTrap?.deactivate();
};

const onMenuTogglerClick = () => toggleMenu();

const onSubmenuToggle = (
  event: MouseEvent,
  force?: boolean,
  button?: HTMLButtonElement,
) => {
  if (!BREAKPOINT_MEDIA_QUERIES.lg.matches && event.type !== 'click') return;

  const btn = button ?? (event.currentTarget as HTMLButtonElement);

  event.preventDefault?.();

  // close any other sub-menu's
  const { subMenuTogglerElements } = getElements();
  [...subMenuTogglerElements]
    .filter((el) => el !== btn)
    .forEach((el) => el.setAttribute('aria-expanded', 'false'));

  const expand = force ?? btn.getAttribute('aria-expanded') === 'false';
  btn.setAttribute('aria-expanded', expand.toString());
};

const toggleMenuTransitions = (force: boolean) => {
  const { navbar } = getElements();
  if (!navbar) return;

  navbar.classList.toggle('navbar--no-transitions', force);
};

const navbarBreakpointChecker = () => {
  const { navbar, subMenuTogglerElements } = getElements();
  if (!navbar) return;

  toggleMenuTransitions(true);

  toggleMenu(false);
  subMenuTogglerElements.forEach((el) =>
    el.setAttribute('aria-expanded', 'false'),
  );

  nextTick(() => toggleMenuTransitions(false));
};

const onPointerEnter = (event: PointerEvent) => {
  if (!BREAKPOINT_MEDIA_QUERIES.menu_lg.matches) return;

  const li = event.currentTarget as HTMLLIElement;
  const btn = li.querySelector('.sub-menu__toggle') as HTMLButtonElement;

  onSubmenuToggle({ ...event, currentTarget: btn }, true, btn);
};

const onPointerLeave = (event: PointerEvent) => {
  if (!BREAKPOINT_MEDIA_QUERIES.menu_lg.matches) return;

  const li = event.currentTarget as HTMLLIElement;
  const btn = li.querySelector('.sub-menu__toggle') as HTMLButtonElement;

  onSubmenuToggle({ ...event, currentTarget: btn }, false, btn);
};

const onEmptySubLinkClick = (e: Event) => {
  e.preventDefault();

  const link = e.currentTarget as HTMLAnchorElement;
  const btn = link.nextElementSibling as HTMLButtonElement;
  btn.click();
};

export default defineModule(
  () => {
    const {
      menuTogglerElement,
      subMenuTogglerElements,
      navSubItems,
      emptySubLinks,
    } = getElements();
    menuTogglerElement?.addEventListener('click', onMenuTogglerClick);
    subMenuTogglerElements.forEach((element) =>
      element.addEventListener('click', onSubmenuToggle),
    );
    navSubItems.forEach((li) => {
      li.addEventListener('pointerenter', onPointerEnter);
      li.addEventListener('pointerleave', onPointerLeave);
    });
    emptySubLinks.forEach((a) =>
      a.addEventListener('click', onEmptySubLinkClick),
    );

    BREAKPOINT_MEDIA_QUERIES.menu_lg.addEventListener(
      'change',
      navbarBreakpointChecker,
      { passive: true },
    );
  },
  () => {
    const {
      menuTogglerElement,
      subMenuTogglerElements,
      navSubItems,
      emptySubLinks,
    } = getElements();
    menuTogglerElement?.removeEventListener('click', onMenuTogglerClick);
    subMenuTogglerElements.forEach((element) =>
      element.removeEventListener('click', onSubmenuToggle),
    );

    navSubItems.forEach((li) => {
      li.removeEventListener('pointerenter', onPointerEnter);
      li.removeEventListener('pointerleave', onPointerLeave);
    });
    emptySubLinks.forEach((a) =>
      a.removeEventListener('click', onEmptySubLinkClick),
    );

    BREAKPOINT_MEDIA_QUERIES.lg.removeEventListener(
      'change',
      navbarBreakpointChecker,
    );
  },
);
