import { defineModule, nextTick } from '@/js/utils/helpers';

const getElements = () => ({
  accordionElements: document.querySelectorAll<HTMLElement>(
    '.content-block--accordion .accordion',
  ),
});

export default defineModule(() => {
  if (!window.location.hash) return;

  const hash = window.location.hash.substring(1, window.location.hash.length);
  if (!hash.length) return;

  const { accordionElements } = getElements();

  const title = decodeURIComponent(hash);

  const accordionElement = [...accordionElements].find((_accordionElement) => {
    const accordionTriggerElement =
      _accordionElement.querySelector<HTMLElement>('.accordion__trigger');
    if (
      !accordionTriggerElement ||
      accordionTriggerElement.textContent?.trim().toLowerCase() !==
        title.toLowerCase()
    ) {
      return false;
    }

    return true;
  });
  if (!accordionElement) return;

  const accordionTriggerElement = accordionElement.querySelector<HTMLElement>(
    '.accordion__trigger',
  );
  if (!accordionTriggerElement) return;

  nextTick(() => {
    accordionElement.scrollIntoView({ behavior: 'smooth' });
    accordionElement.classList.add('accordion--no-transitions');
    accordionTriggerElement.click();
    nextTick(() => {
      accordionElement.classList.remove('accordion--no-transitions');
    });
  });
});
