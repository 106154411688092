import { swup } from '../../vendor/swup.js';
import { toggleModal } from '.';
import { defineModule } from '../../utils/helpers';

let faqModalFormId: number;

const getElements = () => ({
  faqQuestionInput: document.querySelector<HTMLInputElement>(
    '#faq_question_input',
  ),
  formQuestionInput:
    document.querySelector<HTMLInputElement>('#modal-faq input'),
  faqQuestionForm: document.querySelector<HTMLInputElement>(
    '[data-faq-modal] form',
  ),
  modalFaqForm: document.querySelector<HTMLFormElement>('#modal-faq .faq-form'),
});

const onOtherQuestionSubmit = async (e: Event) => {
  e.preventDefault();
  if (!(e.currentTarget instanceof HTMLElement)) return;

  e.currentTarget.setAttribute('aria-expanded', 'true');

  const { faqQuestionInput, formQuestionInput } = getElements();
  if (!(formQuestionInput instanceof HTMLInputElement)) return;

  if (faqQuestionInput?.value) formQuestionInput.value = faqQuestionInput.value;

  toggleModal('faq', true);
};

const onDismissFaqModal = () => {
  swup.navigate(window.location.href, { history: 'replace' });
};

const onModalFormSubmit = (e: JQuery.Event, formId: number) => {
  if (formId !== faqModalFormId) return;

  document.addEventListener('dismiss-modal:faq', onDismissFaqModal, {
    passive: true,
    once: true,
  });
};

export default defineModule(
  () => {
    const { faqQuestionForm, modalFaqForm } = getElements();
    faqQuestionForm?.addEventListener('submit', onOtherQuestionSubmit);

    if (!modalFaqForm) return;

    faqModalFormId = parseInt(modalFaqForm.dataset.formid as string);

    jQuery(document).on('gform_confirmation_loaded', onModalFormSubmit);
  },
  () => {
    const { faqQuestionForm } = getElements();
    faqQuestionForm?.removeEventListener('submit', onOtherQuestionSubmit);

    jQuery(document).off('gform_confirmation_loaded', onModalFormSubmit);

    document.removeEventListener('dismiss-modal:faq', onDismissFaqModal);
  },
);
