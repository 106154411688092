import { getTalent } from '../../api/wordpress';
import { toggleModal } from '.';
import { createElement, defineModule } from '../../utils/helpers';

const getElements = () => ({
  talentElements: document.querySelectorAll<HTMLElement>('[data-talent-id]'),
});

const onTalentClick = async ({ currentTarget }: Event) => {
  if (!(currentTarget instanceof HTMLElement)) return;

  const talentId = currentTarget.getAttribute('data-talent-id');
  if (!talentId) return;

  const talentModalElement = document.querySelector<HTMLElement>(
    '[data-modal="talent"]',
  );
  if (!talentModalElement) return;

  const talent = await getTalent(talentId);
  if (!talent) return;

  const [
    talentNameElement,
    talentDescriptionElement,
    talentExpertiseElement,
    talentSkillsElement,
    talentAvailabilityElement,
  ] = [
    talentModalElement.querySelector('.talent__name'),
    talentModalElement.querySelector('.talent__description'),
    talentModalElement.querySelector('.talent__expertise'),
    talentModalElement.querySelector('.talent__skills'),
    talentModalElement.querySelector('.talent__availability'),
  ];

  if (talentNameElement) {
    talentNameElement.textContent = talent.title.rendered;
  }
  if (talentDescriptionElement) {
    const description = talent.acf['post_type-talent-description'];
    talentDescriptionElement.parentElement?.toggleAttribute(
      'hidden',
      !description,
    );
    talentDescriptionElement.innerHTML = description;
  }
  if (talentExpertiseElement) {
    const expertise = talent.acf['post_type-talent-expertise'];
    talentExpertiseElement.toggleAttribute('hidden', !expertise);
    talentExpertiseElement.innerHTML = expertise;
  }
  if (talentSkillsElement) {
    const skills = talent.acf['post_type-talent-skills']
      .map((skillId) => {
        const skill = talent._embedded['acf:term'].find(
          (term) => term.id === skillId,
        );
        return skill;
      })
      .filter(Boolean);

    talentSkillsElement.parentElement?.toggleAttribute(
      'hidden',
      !skills.length,
    );

    if (skills.length) {
      talentSkillsElement.replaceChildren(
        ...skills.map((skill) =>
          createElement(
            'li',
            { className: 'badge badge--primary badge--no-shadow' },
            [skill!.name],
          ),
        ),
      );
    }
  }
  if (talentAvailabilityElement) {
    const availability = talent.acf['post_type-talent-availability'];
    talentAvailabilityElement.parentElement?.toggleAttribute(
      'hidden',
      !availability,
    );
    talentAvailabilityElement.textContent = availability;
  }

  currentTarget.setAttribute('aria-expanded', 'true');
  toggleModal('talent', true);
};

export default defineModule(
  () => {
    const { talentElements } = getElements();

    talentElements.forEach((talentElement) => {
      talentElement.addEventListener('click', onTalentClick);
    });
  },
  () => {
    const { talentElements } = getElements();

    talentElements.forEach((talentElement) => {
      talentElement.removeEventListener('click', onTalentClick);
    });
  },
);
