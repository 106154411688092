import type Splide from '@splidejs/splide';
import { defineModule } from '@/js/utils/helpers';
import { BREAKPOINTS } from '@/js/utils/breakpoints';

const splides: Splide[] = [];

const getElements = () => ({
  splideElements: document.querySelectorAll<HTMLElement>(
    '.content-block--images-slider .splide',
  ),
});

export default defineModule(
  async () => {
    const { splideElements } = getElements();
    if (!splideElements) return;

    const { Splide } = await import('@splidejs/splide');

    splideElements.forEach((splideElement) => {
      const splide = new Splide(splideElement, {
        type: 'loop',
        pagination: false,
        updateOnMove: true,
        mediaQuery: 'min',
        cloneStatus: false,
        breakpoints: {
          [BREAKPOINTS.md]: {
            gap: '1.5rem',
          },
        },
      });

      splides.push(splide.mount());
    });
  },
  () => {
    while (splides.length > 0) {
      splides.pop()?.destroy(true);
    }
  },
);
