import { defineModule, nextTick } from '@/js/utils/helpers';

const getElements = () => ({
  faqAccordionElements: document.querySelectorAll<HTMLElement>(
    '.content-block--faq .accordion',
  ),
});

export default defineModule(() => {
  if (!window.location.hash) return;

  const hash = window.location.hash.substring(1, window.location.hash.length);
  if (!hash.length) return;

  const { faqAccordionElements } = getElements();

  const title = decodeURIComponent(hash);

  const faqAccordionElement = [...faqAccordionElements].find(
    (_faqAccordionElement) => {
      const accordionTriggerElement =
        _faqAccordionElement.querySelector<HTMLElement>('.accordion__trigger');
      if (
        !accordionTriggerElement ||
        accordionTriggerElement.textContent?.trim().toLowerCase() !==
          title.toLowerCase()
      ) {
        return false;
      }

      return true;
    },
  );
  if (!faqAccordionElement) return;

  const accordionTriggerElement =
    faqAccordionElement.querySelector<HTMLElement>('.accordion__trigger');
  if (!accordionTriggerElement) return;

  nextTick(() => {
    faqAccordionElement.scrollIntoView({ behavior: 'smooth' });
    faqAccordionElement.classList.add('accordion--no-transitions');
    accordionTriggerElement.click();
    nextTick(() => {
      faqAccordionElement.classList.remove('accordion--no-transitions');
    });
  });
});
