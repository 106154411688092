import { defineModule } from '@/js/utils/helpers';

const getElements = () => ({
  selectionMenuElements: document.querySelectorAll<HTMLDetailsElement>(
    '.content-block--home-header details',
  ),
});

const onClick = (e: Event) => {
  const { selectionMenuElements } = getElements();
  selectionMenuElements.forEach((selectionMenuElement) => {
    if (selectionMenuElement.contains(e.target as Node)) return;

    selectionMenuElement.removeAttribute('open');
  });
};

const onKeydown = (e: KeyboardEvent) => {
  if (e.key !== 'Escape') return;

  const { selectionMenuElements } = getElements();
  selectionMenuElements.forEach((selectionMenuElement) => {
    selectionMenuElement.removeAttribute('open');
  });
};

export default defineModule(
  () => {
    document.addEventListener('click', onClick);
    document.addEventListener('keydown', onKeydown);
  },
  () => {
    document.removeEventListener('click', onClick);
    document.removeEventListener('keydown', onKeydown);
  },
);
